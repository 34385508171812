/* Estilos base para el modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 1;
  visibility: visible;
}

.modal-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.modal.hidden {
  opacity: 0;
  visibility: hidden;
}

/* Ajuste general del modal */
.modal-content {
  background: #1f1e1e; /* Fondo negro */
  color: #fff; /* Texto blanco */
  padding: 2rem;
  width: 90%;
  max-width: 850px;
  display: flex;
  border-radius: 8px; /* Sin bordes redondeados */
}

/* Columna de la imagen */
.foto-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
}

.modal-foto {
  width: 100%;
  max-width: 350px; /* Tamaño máximo de la imagen */
  height: auto;
}

/* Columna de información */
.modal-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-info h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.modal-info p {
  font-size: 1.8rem;
  margin-bottom: 15px;
}

/* Botones de colores y talles */
.colores,
.talles {
  margin-bottom: 20px;
  margin-top: 10px;
}

.colores h4,
.talles h4 {
  font-size: 1rem;
  margin-bottom: 5px;
}

.colores button,
.talles button {
  margin: 5px 5px 0 0;
  padding: 8px 12px;
  background: transparent;
  border: 1px solid #ccc;
  color: #fff;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.colores button.selected,
.talles button.selected {
  background-color: #f4b400; /* Fondo blanco para botón seleccionado */
  color: #000; /* Texto negro */
  border: none;
  font-weight: bold;
}

/* Texto de opciones seleccionadas */
.selected-options {
  font-size: 1rem;
  margin-top: 10px;
}

/* Botón "Agregar al carrito" */
.add-to-cart {
  background-color: #f4b400; /* Fondo amarillo */
  color: #000; /* Texto negro */
  font-weight: bold;
  border: none;
  padding: 12px 20px;
  text-align: center;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 20px;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.add-to-cart:hover {
  background-color: #e09e00; /* Color amarillo más oscuro */
}

/* Botón de cerrar */
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: #fff;
  background: transparent;
  border: none;
  cursor: pointer;
}


/* Media Query para pantallas pequeñas (móviles) */
@media (max-width: 768px) {
  .modal-content {
    flex-direction: column; /* Coloca la imagen y la información en columnas */
    padding: 1rem; /* Reduce el padding general */
    width: 95%; /* Aumenta el ancho del modal */
    max-width: 100%; /* El modal ocupa casi toda la pantalla */
  }



  .modal-body {
    flex-direction: column; /* Coloca los elementos uno debajo del otro */
    padding: 0;
  }

  .foto-container {
    padding-right: 0;
    padding-bottom: 10px; /* Espacio entre la imagen y el contenido */
    max-width: 85%;
    margin-top: 20px; /* Añade margen superior */
  }

  .modal-foto {
    max-width: 100%; /* La imagen ocupa todo el ancho disponible */
  }

  .modal-info {
    padding: 0 10px; /* Reduce los márgenes laterales */
    align-items: center; /* Centra el contenido */
    text-align: center; /* Alinea el texto al centro */
  }

  .colores button,
  .talles button {
    padding: 6px 10px; /* Botones más pequeños */
    font-size: 0.8rem;
  }

  .add-to-cart {
    font-size: 0.9rem;
    padding: 10px 15px;
  }

  .close {
    top: 5px;
    right: 5px;
    font-size: 1.2rem; /* Botón cerrar más pequeño */
  }
}
