/* App.css */
body {
  background-color: #000; /* Fondo negro */
  color: #fff; /* Texto blanco */
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

/* Estilo del contenedor principal */
.App {
  padding: 20px;
}

/* Encabezado principal */
h1 {
  text-align: center;
  color: #fff;
  font-size: 2.5rem;
  margin-bottom: 20px;
}
