/* Fondo general de la página */
body {
  background-color: #000;
  color: #fff;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.cart {
  width: 90%;
  margin: 2rem auto;
  background-color: #111; /* Fondo del carrito */
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
}

.cart h2 {
  text-align: center;
  font-size: 1.8rem;
  color: #fff;
  margin-bottom: 1rem;
  border-bottom: 1px solid #444;
  padding-bottom: 0.5rem;
}

.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #444;
  padding: 1rem 0;
}

.cart-item p {
  margin: 0 0.5rem;
  font-size: 1rem;
}

.cart-quantity {
  display: flex;
  align-items: center;
}

.cart-quantity button {
  background-color: #555;
  border: none;
  color: #fff;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  border-radius: 4px;
}

.cart-quantity button:hover {
  background-color: #777;
}

.cart-quantity span {
  margin: 0 0.5rem;
  font-size: 1rem;
  font-weight: bold;
}

.remove-button {
  background-color: #b00020;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 0.9rem;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.remove-button:hover {
  background-color: #e53935;
}

.pay-button {
  display: block;
  width: 100%;
  background-color: #1db954;
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  margin-top: 1.5rem;
  transition: background-color 0.3s ease;
}

.pay-button:hover {
  background-color: #1ed760;
}

.cart-empty {
  text-align: center;
  color: #ccc;
  font-size: 1.2rem;
}

/* Imagen en el carrito */
.cart-item-image {
  width: 80px; /* Tamaño base */
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 1rem;
}

.cart-item-details {
  flex: 1; /* Detalles ocupan más espacio */
}

/* Media Query Única para dispositivos móviles */
@media (max-width: 768px) {
  .cart {
    padding: 1rem;
  }

  .cart-item {
    flex-direction: column;
    align-items: center; /* Centra los elementos */
    text-align: center;
  }

  .cart-item-image {
    width: 100px; /* Imagen más grande */
    height: 100px;
    margin: 0 auto 0.75rem auto; /* Centrada y con espacio inferior */
  }

  .cart-item-details p {
    margin: 0.25rem 0;
  }

  .cart-quantity {
    justify-content: center;
    margin: 0.5rem 0;
  }

  .remove-button {
    width: 100%;
    text-align: center;
    margin-top: 0.5rem;
  }

  .pay-button {
    font-size: 1rem;
    padding: 0.75rem;
  }

  .cart h2 {
    font-size: 1.5rem;
  }
}


.cart-info {
  margin-top: 1rem;
  text-align: center;
}

.cart-deposit-text {
  font-size: 1rem;
  color: #ddd;
  line-height: 1.5;
}

.cart-deposit-text .cart-alias {
  color: #1db954;
  font-weight: bold;
}

.mp-icon {
  width: 80px;
  height: 35px;
  vertical-align: middle;
  margin-left: 0.5rem;
}

/* Ajustes para dispositivos móviles */
@media (max-width: 768px) {
  .cart-info {
    text-align: center;
  }

  .cart-deposit-text {
    font-size: 0.9rem;
  }

  .mp-icon {
    width:80px;
    height: 35px;
  }
}

.cart-total {
  font-size: 1.5rem;
  margin-top: 1.5rem;
  text-align: center;
}