/* Contenedor principal centrado */
.container-inicio {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    max-width: 1200px;
    gap: 20px;
    padding: 0 15px; /* Espaciado horizontal para pantallas pequeñas */
}

/* Galería de productos centrada */
.productos-grid-inicio {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    width: 100%; /* Asegura que las tarjetas ocupen el espacio disponible */
    justify-content: center; /* Centra las columnas del grid */
}

/* Estilo de las tarjetas (mismo diseño que Productos) */
.productoCard {
    background-color: #000000;
    border: 1px solid #ffffff38;
    color: #fff;
    border-radius: 3px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.productoCard img {
    max-width: 100%;
    border-radius: 3px;
    height: auto; /*cuando las imagenes esten tomadas en vertical cambiar esto*/
}

.productoCard h3 {
    margin: 10px 0;
    font-size: 1.2rem;
}

.productoCard p {
    font-size: 0.9rem;
    margin: 5px 0;
}

.productoCard .boton {
    background-color: #f4b400;
    color: #000;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.productoCard .boton:hover {
    background-color: #ffcc00;
}

/* Media queries para móviles */
@media (max-width: 768px) {
    .container-inicio {
        padding: 0 10px;
    }

    .productos-grid-inicio {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* 2 columnas en móviles */
        gap: 15px;
    }
}
