.carrusel {
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 800px; /* Ajusta la altura según la relación de aspecto deseada */
  margin: 20px auto;
  overflow: hidden;
  cursor: pointer; /* Indicamos que se puede hacer clic en el carrusel */
}

.carrusel-image {
  width: 100%;
  height: 100%; /* Esto hace que la imagen ocupe toda la altura del contenedor */
  object-fit: cover; /* Asegura que la imagen cubra todo el área sin distorsionarse */
  display: block;
  border-radius: 10px;
}

.carrusel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 10;
  border-radius: 50%;
}

.carrusel-btn.prev {
  left: 10px;
}

.carrusel-btn.next {
  right: 10px;
}

.carrusel-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}


@media (max-width: 768px){
  .carrusel {
    height: 700px; /* Ajusta la altura para dispositivos móviles */

  width: 80%;
  }
}